<template>
  <div class="card mb-5 mb-xl-10">
    <div class="card-body pt-9 pb-0">
      <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">
        <!--begin::Card header-->
        <div class="card-header cursor-pointer">
          <!--begin::Card title-->
          <div class="card-title m-0">
            <h3 class="fw-bolder m-0">Client List</h3>
          </div>
          <!--end::Card title-->
          <!--begin::Card toolbar-->
          <div class="card-toolbar">
            <a
              href="#"
              class="btn btn-sm btn-flex btn-light-primary"
              data-bs-toggle="modal"
              data-bs-target="#modal_new_client"
            >
              <span class="svg-icon svg-icon-3">
                <inline-svg src="/media/icons/duotune/general/gen035.svg" />
              </span>
              Add new client</a
            >
          </div>
          <!--end::Card toolbar-->
          <!--begin::Search-->
          <input
            type="text"
            class="form-control form-control-lg form-control-solid px-5 mb-2"
            v-model="searchClients"
            name="search"
            placeholder="Search by name..."
            data-kt-search-element="input"
          />
          <p v-if="isDataReady" class="px-5 mb-5">
            {{ filterClients.length }} returned
          </p>
        </div>
        <!--end::Search-->
        <!--begin::Card header-->

        <!--begin::Card body-->
        <div class="card-body pt-0" v-if="isDataReady">
          <!--begin:: Flex Container-->
          <div
            id="incline-flex-table"
            class="d-flex flex-row flex-wrap pb-5"
            v-for="(item, index) in filterClients"
            :key="item.clientId"
            :clientId="'client_' + index"
            :class="{
              'incline-flex-table-background': index % 2 === 0,
              green: index % 2 !== 0,
            }"
          >
            <div
              class="d-flex flex-column flex-sm-column flex-fill flex-wrap w-25"
            >
              <p class="incline-flex-table-th mb-0">Name</p>
              <p class="incline-flex-table-td mb-0">
                <strong>{{ item.name }}</strong>
              </p>
              <p class="incline-flex-table-th">Primary Contact</p>
              <p class="incline-flex-table-td m-0">
                {{ item.primaryContactName }}
              </p>
              <small
                ><a
                  style="padding: 0px 5px 0px 5px"
                  :href="'mailto:' + item.primaryContactEmail"
                >
                  {{ item.primaryContactEmail }}</a
                ></small
              >
              <div
                v-if="
                  currentUser.role === 'SuperAdmin' ||
                  currentUser.role === 'LicensedDistributor'
                "
              >
                <p class="incline-flex-table-th">Content Expert</p>
                <p
                  v-if="item.primaryContentExpert === 'Not assigned.'"
                  class="incline-flex-table-td m-0 text-danger"
                >
                  <span style="font-size: 13px; font-weight: bold"
                    >Primary Content Expert needs to be assigned!</span
                  >
                </p>
                <p v-else class="incline-flex-table-td m-0">
                  {{ item.primaryContentExpert }}
                </p>
              </div>
            </div>
            <div class="d-flex flex-column flex-fill flex-wrap">
              <p class="incline-flex-table-th"># of Employees</p>
              <p class="incline-flex-table-td">{{ item.numOfEmployees }}</p>
            </div>
            <div class="d-flex flex-column flex-fill flex-wrap">
              <p class="incline-flex-table-th">Contract Id</p>
              <p class="incline-flex-table-td">{{ item.clientContractId }}</p>
            </div>
            <div class="d-flex flex-column flex-fill flex-wrap">
              <p class="incline-flex-table-th">Service Fee</p>
              <p class="incline-flex-table-td">
                ${{ item.serviceFee.toFixed(2) }}
              </p>
            </div>
            <div class="d-flex flex-column flex-fill">
              <p class="incline-flex-table-th">Actions</p>
              <div class="d-flex flex-row">
                <!--::begin Folders -->
                <p class="incline-flex-table-td">
                  <router-link
                    :to="{
                      name: 'client-surveys',
                      params: {
                        clientid: Number(item.clientId),
                        licenseddistributorid: Number(
                          item.licensedDistributorId
                        ),
                      },
                    }"
                    class="
                      btn btn-icon btn-bg-light btn-active-color-primary btn-sm
                      me-1
                    "
                  >
                    <span class="svg-icon svg-icon-3">
                      <inline-svg
                        src="../media/icons/duotune/files/fil012.svg"
                      /> </span
                  ></router-link>
                </p>
                <!--::end Folders -->
                <!--::begin Edit -->
                <p class="incline-flex-table-td">
                  <router-link
                    :to="{
                      name: 'client-overview',
                      params: {
                        clientid: Number(item.clientId),
                      },
                    }"
                    class="
                      btn btn-icon btn-bg-light btn-active-color-primary btn-sm
                      me-1
                    "
                  >
                    <span class="svg-icon svg-icon-3">
                      <inline-svg
                        src="../media/icons/duotune/art/art005.svg"
                      /> </span
                  ></router-link>
                </p>
                <!--::end Edit -->
                <!--::begin Delete -->
                <p class="incline-flex-table-td">
                  <a
                    href="#"
                    class="
                      btn btn-icon btn-bg-light btn-active-color-primary btn-sm
                    "
                    @click="deleteClient(item.clientId)"
                  >
                    <span class="svg-icon svg-icon-3">
                      <inline-svg
                        src="../media/icons/duotune/general/gen027.svg"
                      />
                    </span>
                  </a>
                </p>
                <!--::end Delete -->
              </div>
            </div>
          </div>
          <!--end::Flex Container-->
        </div>
        <div v-else>
          <TableSkeleton></TableSkeleton>
        </div>
        <!--end::Card body-->
      </div>
    </div>
  </div>
  <NewClientModal
    :displayModal="displayModal"
    :userRole="currentUser.role"
    :licensedDistAspNetUserId="currentUser.id"
    :licensedDistAssocAspNetUserId="0"
    :routeName="routeName"
  ></NewClientModal>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed, watch } from "vue";

import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import NewClientModal from "@/components/modals/forms/NewClientModal.vue";
import TableSkeleton from "@/components/widgets/skeletons/TableSkeleton.vue";

export default defineComponent({
  name: "licensed-distributor-client-listing",
  components: {
    NewClientModal,
    TableSkeleton,
  },
  setup() {
    const store = useStore();
    const currentUser = store.getters.currentUser;
    const route = useRoute();
    const routeName = computed(() => route.name);
    let displayModal = ref<boolean>(false);
    const isDataReady = ref(false);

    const clientUsers = computed(() => {
      return store.getters.getClientList;
    });
    const searchClients = ref("");

    store
      .dispatch(Actions.GET_LICENSED_DISTRIBUTOR_CLIENTS, currentUser.id)
      .then(() => {
        //
      })
      .catch(() => {
        Swal.fire({
          text: store.getters.getErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "There was a problem retrieving clients.",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      });

    onMounted(() => {
      setCurrentPageTitle("Clients");
      setCurrentPageBreadcrumbs("Client List", ["Client"]);
    });

    const filterClients = computed(() => {
      return clientUsers.value.filter((client) => {
        return (
          client.name
            .toLowerCase()
            .indexOf(searchClients.value.toLowerCase()) != -1
        );
      });
    });

    watch([clientUsers], () => {
      isDataReady.value = true;
    });

    const deleteClient = (id) => {
      Swal.fire({
        title: "Are you sure you want to delete this client?",
        showCancelButton: true,
        confirmButtonText: "Delete",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          store
            .dispatch(Actions.DELETE_CLIENT, id)
            .then(() => {
              Swal.fire("Deleted!", "", "success");
              for (let i = 0; i < clientUsers.value.length; i++) {
                if (clientUsers.value[i].id === id) {
                  clientUsers.value.splice(i, 1);
                }
              }
            })
            .catch(() => {
              Swal.fire({
                text: store.getters.getErrors[0],
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "There was a problem deleting the client.",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            });
        }
      });
    };

    return {
      currentUser,
      routeName,
      isDataReady,
      clientUsers,
      searchClients,
      filterClients,
      displayModal,
      deleteClient,
    };
  },
});
</script>
